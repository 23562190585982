
import { setPageHeader } from "@/core/helpers/toolbar";
import { defineComponent, ref, watchEffect } from "vue";
import AddAttribute from "@/modules/catalog/attributes/components/AddAttribute.vue";
import EditAttribute from "@/modules/catalog/attributes/components/EditAttribute.vue";
import AttributesList from "@/modules/catalog/attributes/components/AttributesList.vue";

import AddAttributeValue from "@/modules/catalog/attribute-values/components/AddAttributeValue.vue";
import EditAttributeValue from "@/modules/catalog/attribute-values/components/EditAttributeValue.vue";
import AttributeValueList from "@/modules/catalog/attribute-values/components/AttributeValuesList.vue";
export default defineComponent({
	components: {
		AddAttribute,
		EditAttribute,
		AttributesList,

		AddAttributeValue,
		EditAttributeValue,
		AttributeValueList,
	},

	setup() {
		const tabsValue = ref(0);
		const taxRuleTab = ref(false);

		watchEffect(() => {
			if (tabsValue.value == 0) {
				setPageHeader({
					title: "message.ATTRIBUTES",
					actionButton: {
						ability: "add_attribute",
						pageAction: {
							action: "addAttributeAction",
						},
						button: {
							icon: "bi bi-plus",
							openModal: true,
						},
					},
					breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.CATALOG", link: "#" }, { name: "message.ATTRIBUTES" }],
				});
			} else {
				taxRuleTab.value = true;
				setPageHeader({
					title: "message.ATTRIBUTE message.VALUES",
					actionButton: {
						ability: "add_attribute_value",
						pageAction: {
							action: "addAttributeValueAction",
						},
						button: {
							icon: "bi bi-plus",
							openModal: true,
						},
					},
					breadCrumbs: [{ name: "message.HOME", link: "/dashboard" }, { name: "message.CATALOG", link: "#" }, { name: "message.ATTRIBUTE message.VALUES" }],
				});
			}
		});

		return {
			tabsValue,
			taxRuleTab,
		};
	},
});
