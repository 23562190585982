import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AttributesList = _resolveComponent("AttributesList")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_AttributeValueList = _resolveComponent("AttributeValueList")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_AddAttribute = _resolveComponent("AddAttribute")!
  const _component_EditAttribute = _resolveComponent("EditAttribute")!
  const _component_AddAttributeValue = _resolveComponent("AddAttributeValue")!
  const _component_EditAttributeValue = _resolveComponent("EditAttributeValue")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_tabs, {
      class: "attribute-tabs",
      "tab-position": 'top',
      modelValue: _ctx.tabsValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.tabsValue = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_tab_pane, {
          label: _ctx.$t('message.ATTRIBUTES')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_AttributesList)
          ]),
          _: 1
        }, 8, ["label"]),
        (_ctx.$can('view_attribute_values_list'))
          ? (_openBlock(), _createBlock(_component_el_tab_pane, {
              key: 0,
              label: `${_ctx.$t('message.ATTRIBUTE')} ${_ctx.$t('message.VALUES')}`
            }, {
              default: _withCtx(() => [
                (_ctx.taxRuleTab == true)
                  ? (_openBlock(), _createBlock(_component_AttributeValueList, { key: 0 }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["modelValue"]),
    _createVNode(_component_AddAttribute),
    _createVNode(_component_EditAttribute),
    _createVNode(_component_AddAttributeValue),
    _createVNode(_component_EditAttributeValue)
  ], 64))
}