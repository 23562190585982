
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { GET_ATTRIBUTES } from "../graphql/Queries";
import { CREATE_ATTRIBUTE } from "../graphql/Mutations";
import { AddAttributeForm } from "../interfaces";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "add new attribute",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const locales = ref([]) as Record<any, any>;
		const locale = ref("da");
		const emitter: any = inject("emitter");
		const i18n = useI18n();
		const addAttributeForm = ref<null | HTMLFormElement>(null);

		// Given Add Attribute Form Interface
		const attributeData = ref<AddAttributeForm>({
			name: "",
		});

		// Store Name in Danish and English
		const jsonNameFields = ref({}) as Record<any, any>;

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});

				jsonNameFields.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// On change input value
		const changeLang = (e: string) => {
			attributeData.value.name = jsonNameFields.value[e].name;
		};

		// update on change value input
		const updateValue = (e: string) => {
			jsonNameFields.value[locale.value].name = e;
		};

		// Submit Handler Request
		const submitHandlerForm = async () => {
			addAttributeForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					for (const i in jsonNameFields.value) {
						if (jsonNameFields.value[i].name == "") {
							jsonNameFields.value[i].name = attributeData.value.name;
						}
					}

					const formData = {
						name: JSON.stringify(jsonNameFields.value),
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: CREATE_ATTRIBUTE,
						variables: { input: formData },
						update: (store, { data: { create_attribute } }) => {
							const pervious_record = store.readQuery({
								query: GET_ATTRIBUTES,
								variables: {
									page: 1,
									limit: 10,
								},
							}) as Record<any, any>;
							store.writeQuery({
								query: GET_ATTRIBUTES,
								variables: {
									page: 1,
									limit: 10,
								},
								data: {
									attributes: {
										...pervious_record.attributes,
										data: [create_attribute, ...pervious_record.attributes.data],
									},
								},
							});
							modal.value.hide();
							loading.value = false;
							Notify.success(i18n.t("message.RECORD_ADDED_SUCCESSFULLY"));
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
      		locale.value = "da"
			addAttributeForm.value?.resetFields();
			for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
				jsonNameFields.value[i].name = "";
			}
		};

		// Emitter To Open Model
		emitter.on("addAttributeAction", () => {
			resetForm();
			modal.value = new Modal(document.getElementById("modal_add_attribute")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			locales,
			locale,
			attributeData,
			addAttributeForm,
			submitHandlerForm,
			changeLang,
			updateValue,
		};
	},
});
