
import { defineComponent, ref, inject, watchEffect, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_ATTRIBUTE_VALUES, SEARCH_ATTRIBUTE_VALUES } from '../graphql/Queries';
import { useI18n } from 'vue-i18n';
import { DELETE_ATTRIBUTE_VALUE } from '../graphql/Mutations';
import Search from '../../../../components/search/Search.vue';
import Table from '../../../../components/Table/Table.vue';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { dropdown_handler } from '../../../../core/helpers/dropdownHandler';

export default defineComponent({
    name: 'attribute values list',
    components: { Search, Table, InnerLoader },
    setup() {
        const i18n = useI18n();
        const store = useStore();
        const emitter: any = inject('emitter');
        const attribute_values = ref([]);
        const system_locale = ref();
        const pagination = ref({}) as Record<any, any>;
        const loader = ref(false);
        const loading = ref(false);
        const clickHandler = ref() as Record<any, any>;
        const currentPage = ref();
        const permissions = store.getters.getPermissions;
        const activeIndex = ref(-1);
        const searchObservable: any = ref(null);
        const observable: any = ref(null);

        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.ATTRIBUTE',
                key: 'attribute'
            },
            {
                label: 'message.TYPE',
                key: 'variant_type'
            },
            {
                label: 'message.VALUE',
                key: 'variant_value'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const dropdownHandler = (index, id) => {
            activeIndex.value = index === id ? null : id;
        };
        const handleClickOutside = event => {
            if (!event.target.closest('.action-btn')) {
                activeIndex.value = -1;
            }
        };

        const filterObject = ref([
            {
                label: 'message.NAME',
                value: 'name'
            },
            {
                label: 'message.ATTRIBUTE_NAME',
                value: 'attribute'
            }
        ]);

        const get_attribute_values = (page = 1, reload = false) => {
            if (searchObservable.value != null) {
                searchObservable.value?.unsubscribe();
                searchObservable.value = null;
            }
            if (page == 1) observable.value?.unsubscribe();
            loader.value = true;
            observable.value = Apollo.watchQuery({
                query: GET_ATTRIBUTE_VALUES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                variables: {
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                reload ? (currentPage.value = 1) : false;
                clickHandler.value = get_attribute_values;
                attribute_values.value = data.attribute_values.data;
                pagination.value = data.attribute_values;
                loader.value = false;
            });
        };

        const handleCurrentChange = (page: number) => {
            clickHandler.value(page);
        };

        get_attribute_values(1);

        const handleEdit = (attribute_value: Record<any, any>) => {
            attribute_value = { ...attribute_value, current_page: pagination.value.current_page };
            emitter.emit('editAttributeValueAction', attribute_value);
        };

        const searchHandler = (page = 1) => {
            if (observable.value != null) {
                observable.value?.unsubscribe();
                observable.value = null;
            }
            searchObservable.value?.unsubscribe();
            const searchData = store.getters.getSearchData;
            loader.value = true;
            searchObservable.value = Apollo.watchQuery({
                query: SEARCH_ATTRIBUTE_VALUES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    page: page,
                    limit: 10,
                    filter: searchData.data.select,
                    search_key: searchData.data.input,
                    locale: system_locale.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                attribute_values.value = data.search_attribute_values.data;
                pagination.value = data.search_attribute_values;
                loader.value = false;
            });
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_ATTRIBUTE_VALUE,
                        variables: { id: id },
                        update: (store, { data: { delete_attribute_value } }) => {
                            // const read_data = store.readQuery({
                            // 	query: GET_ATTRIBUTE_VALUES,
                            // 	variables: {
                            // 		page: pagination.value.current_page,
                            // 		limit: 10,
                            // 	},
                            // }) as Record<any, any>;
                            // const data = read_data.attribute_values.data.filter((t: any) => t.id !== delete_attribute_value.id);
                            // store.writeQuery({
                            // 	query: GET_ATTRIBUTE_VALUES,
                            // 	data: {
                            // 		attribute_values: {
                            // 			...read_data.attribute_values,
                            // 			data: [...data],
                            // 		},
                            // 	},
                            // 	variables: {
                            // 		page: pagination.value.current_page,
                            // 		limit: 10,
                            // 	},
                            // });
                            if (searchObservable.value != null) searchHandler(pagination.value.current_page);
                            else get_attribute_values(pagination.value.current_page);
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        onMounted(() => {
            document.addEventListener('click', handleClickOutside);
        });

        const exportFile = data => {
            const searchData = store.getters.getSearchData;
            loading.value = true;
            const sub = Apollo.watchQuery({
                query: SEARCH_ATTRIBUTE_VALUES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                variables: {
                    page: 0,
                    limit: 1,
                    filter: searchData?.data?.select ? searchData?.data?.select : '',
                    search_key: searchData?.data?.input ? searchData?.data?.input : '',
                    locale: system_locale.value,
                    type: data?.type,
                    date_from: data?.dateFrom,
                    date_to: data?.dateTo
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loading.value = false;
                } else if (data) {
                    window.open(data?.search_attribute_values?.data[0]?.path, '_blank');
                    loading.value = false;
                }

                clickHandler.value = searchHandler;
                attribute_values.value = data.search_attribute_values.data;
                pagination.value = data.search_attribute_values;
                loading.value = false;
                sub.unsubscribe();
            });
        };

        onUnmounted(() => {
            observable.value?.unsubscribe();
            searchObservable.value?.unsubscribe();
        });

        return {
            attribute_values,
            pagination,
            system_locale,
            loading,
            filterObject,
            currentPage,
            permissions,
            columns,
            loader,
            exportFile,
            handleCurrentChange,
            searchHandler,
            get_attribute_values,
            handleEdit,
            handleDelete,
            activeIndex,
            dropdownHandler,
            handleClickOutside
        };
    }
});
