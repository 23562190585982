
import { Modal } from "bootstrap";
import { defineComponent, ref, inject } from "vue";
import { Input, Select } from "@/components/input-elements";
import { Apollo, Notify } from "@/core/services";
import { GET_ATTRIBUTES } from "../graphql/Queries";
import { UPDATE_ATTRIBUTE } from "../graphql/Mutations";
import { EditAttributeForm } from "../interfaces";
import { GET_LOCALES } from "@/modules/common/locales/graphql/Queries";
import { useI18n } from "vue-i18n";
import InnerLoader from "@/components/InnerLoader.vue";

export default defineComponent({
	name: "edit attribute",
	components: {
		Input,
		Select,
		InnerLoader,
	},

	setup() {
		const modal: any = ref();
		const loading = ref(false);
		const locales = ref([]) as Record<any, any>;
		const locale = ref("da");
		const emitter: any = inject("emitter");
    const current_page = ref(1);
    const i18n = useI18n();
		const editAttributeForm = ref<null | HTMLFormElement>(null);

		// Given Edit Attribute Form Interface
		const editAttributeData = ref<EditAttributeForm>({
			id: null,
			name: "",
		});

		// Store Name in Danish and English
		const jsonNameFields = ref({}) as Record<any, any>;

		const loadLang = () => {
			const locales_data = Apollo.readQuery({
				query: GET_LOCALES,
			});
			locales_data.locales.forEach((element) => {
				locales.value.push({
					label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
					value: element.locale,
				});

				jsonNameFields.value[element.locale] = { name: "" };
			});
		};

		loadLang();

		// On change input value
		const changeLang = (e: string) => {
			editAttributeData.value.name = jsonNameFields.value[e].name;
		};

		// update on change value input
		const updateValue = (e: string) => {
			jsonNameFields.value[locale.value].name = e;
		};

		// Submit Handler Request
		const submitHandlerForm = async () => {
			editAttributeForm.value?.validate(async (valid: boolean) => {
				if (valid) {
					for (const i in jsonNameFields.value) {
						if (jsonNameFields.value[i].name == "") {
							jsonNameFields.value[i].name = editAttributeData.value.name;
						}
					}
					const formData = {
						id: editAttributeData.value.id,
						name: JSON.stringify(jsonNameFields.value),
					};

					loading.value = true;
					await Apollo.mutate({
						mutation: UPDATE_ATTRIBUTE,
						variables: { input: formData },
						update: (store, { data: { update_attribute } }) => {
							// const pervious_record = store.readQuery({
							// 	query: GET_ATTRIBUTES,
							// 	variables: {
							// 		page: current_page.value,
							// 		limit: 10,
							// 	},
							// }) as Record<any, any>;
							// store.writeQuery({
							// 	query: GET_ATTRIBUTES,
							// 	variables: {
							// 		page: current_page.value,
							// 		limit: 10,
							// 	},
							// 	data: {
							// 		attributes: {
							// 			...pervious_record.attributes,
							// 		},
							// 		variables: {
							// 			id: update_attribute.id,
							// 		},
							// 	},
							// });
							modal.value.hide();
							loading.value = false;
							Notify.success(i18n.t("message.RECORD_UPDATED_SUCCESSFULLY"));
						},
					}).catch(() => {
						loading.value = false;
					});
				}
			});
		};

		// Reset Form Data On Model Popup
		const resetForm = () => {
			emitter.emit("clearInput");
      current_page.value = 1;
      locale.value = "da"
      editAttributeForm.value?.resetFields();
			for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
				jsonNameFields.value[i].name = "";
			}
		};

		// Emitter To Open Model
		emitter.on("editAttributeAction", (attribute: Record<any, any>) => {
			resetForm();
			locale.value = "da";
      current_page.value = attribute.current_page;
      editAttributeData.value.id = attribute.id;
			// Json decode name
			const json_decode_name = JSON.parse(attribute.name);
			for (const i in json_decode_name) {
				i == "da" ? (editAttributeData.value.name = json_decode_name[i].name) : "";
				jsonNameFields.value[i].name = json_decode_name[i].name;
			}
			modal.value = new Modal(document.getElementById("modal_edit_attribute")) as HTMLElement;
			modal.value.show();
		});

		return {
			loading,
			locales,
			locale,
			editAttributeData,
			editAttributeForm,
			submitHandlerForm,
			changeLang,
			updateValue,
		};
	},
});
