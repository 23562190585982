
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { GET_ATTRIBUTE_VALUES } from '../graphql/Queries';
import { UPDATE_ATTRIBUTE_VALUE } from '../graphql/Mutations';
import { EditAttributeValueForm } from '../interfaces';
import { GET_ATTRIBUTES } from '../../attributes/graphql/Queries';
import { useI18n } from 'vue-i18n';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import InnerLoader from '../../../../components/InnerLoader.vue';

export default defineComponent({
    name: 'edit attribute value',
    components: {
        Input,
        Select,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const i18n = useI18n();
        const loading = ref(false);
        const locales = ref([]) as Record<any, any>;
        const locale = ref('da');
        const attributes = ref([]) as Record<any, any>;
        const emitter: any = inject('emitter');
        const current_page = ref(1);
        const variant_types = ref({});
        const editAttributeValueForm = ref<null | HTMLFormElement>(null);

        const attributeValuesList = ref([]);
        // Get Attributes list
        const get_attribute = () => {
            Apollo.watchQuery({
                query: GET_ATTRIBUTES,
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only'
            }).subscribe(({ data }) => {
                attributeValuesList.value = [];
                attributeValuesList.value = data.attributes.data;
            });
        };

        variant_types.value = [
            {
                label: 'message.COLOR',
                value: 'Color'
            },
            {
                label: 'message.SIZE',
                value: 'Size'
            },
            {
                label: 'message.WEIGHT',
                value: 'Weight'
            }
        ];

        watchEffect(() => {
            attributes.value = [];
            attributeValuesList.value.forEach((element: Record<any, any>) => {
                let defaultLocale = '' as any;
                if (JSON.parse(element.name)[`${locale.value}`] == undefined) {
                    defaultLocale = 'da';
                } else {
                    defaultLocale = locale.value;
                }
                attributes.value.push({
                    value: element.id,
                    label: JSON.parse(element.name)[`${defaultLocale}`].name
                });
            });
        });

        // Given Edit Attribute Value Form Interface
        const editAttributeValueData = ref<EditAttributeValueForm>({
            id: null,
            name: '',
            attribute_id: null,
            variant_type: '',
            variant_value: ''
        });

        // Store Name in Danish and English
        const jsonNameFields = ref({}) as Record<any, any>;

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                jsonNameFields.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        // On change input value
        const changeLang = (e: string) => {
            editAttributeValueData.value.name = jsonNameFields.value[e].name;
        };

        // update on change value input
        const updateValue = (e: string) => {
            jsonNameFields.value[locale.value].name = e;
        };

        // Submit Handler Request
        const submitHandlerForm = async () => {
            editAttributeValueForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    for (const i in jsonNameFields.value) {
                        if (jsonNameFields.value[i].name == '') {
                            jsonNameFields.value[i].name = editAttributeValueData.value.name;
                        }
                    }
                    const formData = {
                        id: editAttributeValueData.value.id,
                        name: JSON.stringify(jsonNameFields.value),
                        attribute_id: editAttributeValueData.value.attribute_id,
                        variant_type: editAttributeValueData.value.variant_type,
                        variant_value: editAttributeValueData.value.variant_value
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_ATTRIBUTE_VALUE,
                        variables: { input: formData },
                        update: (store, { data: { update_attribute_value } }) => {
                            // const pervious_record = store.readQuery({
                            // 	query: GET_ATTRIBUTE_VALUES,
                            // 	variables: {
                            // 		page: current_page.value,
                            // 		limit: 10,
                            // 	},
                            // }) as Record<any, any>;
                            // store.writeQuery({
                            // 	query: GET_ATTRIBUTE_VALUES,
                            // 	variables: {
                            // 		page: current_page.value,
                            // 		limit: 10,
                            // 	},
                            // 	data: {
                            // 		attribute_values: {
                            // 			...pervious_record.attribute_values,
                            // 		},
                            // 		variables: {
                            // 			id: update_attribute_value.id,
                            // 		},
                            // 	},
                            // });
                            modal.value.hide();
                            loading.value = false;
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        }
                    }).catch(e => {
                        console.log(e);
                        loading.value = false;
                    });
                }
            });
        };

        const changeType = () => {
            editAttributeValueData.value.variant_value = '';
        };

        // Reset Form Data On Model Popup
        const resetForm = () => {
            get_attribute();
            emitter.emit('clearInput');
            current_page.value = 1;
            editAttributeValueForm.value?.resetFields();
            for (const i of Object.getOwnPropertyNames(jsonNameFields.value)) {
                jsonNameFields.value[i].name = '';
            }
        };

        // Emitter To Open Model
        emitter.on('editAttributeValueAction', (attribute_value: Record<any, any>) => {
            resetForm();
            current_page.value = attribute_value.current_page;
            locale.value = 'da';
            const json_decode_name = JSON.parse(attribute_value.name);
            for (const i in json_decode_name) {
                i == 'da' ? (editAttributeValueData.value.name = json_decode_name[i].name) : '';
                jsonNameFields.value[i].name = json_decode_name[i].name;
            }

            editAttributeValueData.value.id = attribute_value.id;
            editAttributeValueData.value.attribute_id = attribute_value.attribute.id;
            editAttributeValueData.value.variant_type = attribute_value.variant_type;
            editAttributeValueData.value.variant_value = attribute_value.variant_value;

            modal.value = new Modal(document.getElementById('modal_edit_attribute_value')) as HTMLElement;
            modal.value.show();
        });

        return {
            loading,
            locales,
            locale,
            variant_types,
            attributes,
            editAttributeValueData,
            editAttributeValueForm,
            submitHandlerForm,
            changeType,
            changeLang,
            updateValue
        };
    }
});
