import { gql } from "graphql-tag";
import { ATTRIBUTE_VALUE_FIELDS } from "./Fragments";

export const CREATE_ATTRIBUTE_VALUE = gql`
	mutation CreateAttributeValue($input: AttributeValueInput!) {
		create_attribute_value(attribute_value: $input) {
			...AttributeValueFields
		}
	}
	${ATTRIBUTE_VALUE_FIELDS}
`;

export const UPDATE_ATTRIBUTE_VALUE = gql`
	mutation UpdateAttributeValue($input: AttributeValueInput!) {
		update_attribute_value(attribute_value: $input) {
			...AttributeValueFields
		}
	}
	${ATTRIBUTE_VALUE_FIELDS}
`;

export const DELETE_ATTRIBUTE_VALUE = gql`
	mutation DeleteAttributeValue($id: Int!) {
		delete_attribute_value(id: $id) {
			...AttributeValueFields
		}
	}
	${ATTRIBUTE_VALUE_FIELDS}
`;

export default { CREATE_ATTRIBUTE_VALUE, UPDATE_ATTRIBUTE_VALUE, DELETE_ATTRIBUTE_VALUE };
