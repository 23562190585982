
import {defineComponent, ref, inject, watchEffect, onMounted, onUnmounted} from "vue";
import { useStore } from "vuex";
import { Apollo, Notify } from "@/core/services";
import { GET_ATTRIBUTES, SEARCH_ATTRIBUTES } from "../graphql/Queries";
import { DELETE_ATTRIBUTE } from "../graphql/Mutations";
import { useI18n } from "vue-i18n";
import Search from "../../../../components/search/Search.vue";
import Table from "../../../../components/Table/Table.vue";
import InnerLoader from "../../../../components/InnerLoader.vue";
import { dropdown_handler } from "../../../../core/helpers/dropdownHandler";

export default defineComponent({
	name: "attribute list",
	components: { Search, Table, InnerLoader },
	setup() {
		const i18n = useI18n();
		const store = useStore();
		const emitter: any = inject("emitter");
		const attributes = ref([]);
		const system_locale = ref();
		const pagination = ref({}) as Record<any, any>;
		const loader = ref(false);
		const loading = ref(false);
		const clickHandler = ref() as Record<any, any>;
		const currentPage = ref();
		const permissions = store.getters.getPermissions;
		const activeIndex = ref(-1);
    const searchObservable: any = ref(null);
    const observable: any = ref(null);


    const columns = ref([
			{
				label: "message.NAME",
				key: "name",
			},
			{
				label: "message.CREATED_AT",
				key: "created_at",
			},
			{
				label: "message.ACTIONS",
				key: "actions",
				textAlignment: "center",
			},
		]);

		watchEffect(() => {
			system_locale.value = i18n.fallbackLocale.value;
		});

		const dropdownHandler = (index, id) => {
			activeIndex.value = index === id ? null : id;
		};
		const handleClickOutside = (event) => {
			if (!event.target.closest(".action-btn")) {
				activeIndex.value = -1;
			}
		};

		const get_attributes = (page = 1, reload = false) => {
      if (searchObservable.value != null) {
        searchObservable.value?.unsubscribe();
        searchObservable.value = null;
      }
      if(page == 1) observable.value?.unsubscribe();
      loader.value = true;
      observable.value = Apollo.watchQuery({
				query: GET_ATTRIBUTES,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-and-network",
				errorPolicy: "all",
				variables: {
					page: page,
					limit: 10,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loader.value = false;
				}
				reload ? (currentPage.value = 1) : false;
				clickHandler.value = get_attributes;
				attributes.value = data.attributes.data;
				pagination.value = data.attributes;
				loader.value = false;
			});
		};

		const handleCurrentChange = (page: number) => {
			clickHandler.value(page);
		};

    const searchHandler = (page = 1) => {
      if(observable.value != null) {
        observable.value?.unsubscribe();
        observable.value = null
      }
      searchObservable.value?.unsubscribe();
      const searchData = store.getters.getSearchData;
      loader.value = true;
      searchObservable.value = Apollo.watchQuery({
        query: SEARCH_ATTRIBUTES,
        errorPolicy: "all",
        fetchPolicy: "network-only",
        nextFetchPolicy: "network-only",
        variables: {
          page: page,
          limit: 10,
          search_key: searchData.data.input,
          locale: system_locale.value,
        },
      }).subscribe(({ data, errors }) => {
        if (errors) {
          loader.value = false;
        }

        clickHandler.value = searchHandler;
        attributes.value = data.search_attributes.data;
        pagination.value = data.search_attributes;
        loader.value = false;
      });
    };

		get_attributes(1);

		const handleEdit = (attribute: Record<any, any>) => {
      attribute = ({...attribute, current_page: pagination.value.current_page});
      emitter.emit("editAttributeAction", attribute);
		};

		const handleDelete = (id: number) => {
			store.getters.appInstance.$messageBox
				.confirm(`${i18n.t("message.ARE_YOU_SURE_TO_DELETE_RECORD")}?`, i18n.t("message.INFO"), {
					confirmButtonText: i18n.t("message.OK"),
					cancelButtonText: i18n.t("message.CANCEL"),
					type: "info",
				})
				.then(async () => {
					loader.value = true;
					await Apollo.mutate({
						mutation: DELETE_ATTRIBUTE,
						variables: { id: id },
						update: (store, { data: { delete_attribute } }) => {
							// const read_data = store.readQuery({
							// 	query: GET_ATTRIBUTES,
							// 	variables: {
							// 		page: pagination.value.current_page,
							// 		limit: 10,
							// 	},
							// }) as Record<any, any>;
							// const data = read_data.attributes.data.filter((t: any) => t.id !== delete_attribute.id);
							// store.writeQuery({
							// 	query: GET_ATTRIBUTES,
							// 	data: {
							// 		attributes: {
							// 			...read_data.attributes,
							// 			data: [...data],
							// 		},
							// 	},
							// 	variables: {
							// 		page: pagination.value.current_page,
							// 		limit: 10,
							// 	},
							// });
              if (searchObservable.value != null) searchHandler(pagination.value.current_page)
              else get_attributes(pagination.value.current_page)
              loader.value = false;
						},
					});
          // get_attributes(pagination.value.current_page, false)
					Notify.success(i18n.t("message.RECORD_DELETED_SUCCESSFULLY"));
				})
				.catch(() => {
					loader.value = false;
				});
		};

		onMounted(() => {
			document.addEventListener("click", handleClickOutside);
		});

		const exportFile = (data) => {
			const searchData = store.getters.getSearchData;
			loading.value = true;
			const sub = Apollo.watchQuery({
				query: SEARCH_ATTRIBUTES,
				errorPolicy: "all",
				fetchPolicy: "network-only",
				variables: {
					page: 0,
					limit: 1,
					filter: searchData?.data?.select ? searchData?.data?.select : "",
					search_key: searchData?.data?.input ? searchData?.data?.input : "",
					locale: system_locale.value,
					type: data?.type,
					date_from: data?.dateFrom,
					date_to: data?.dateTo,
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					loading.value = false;
				} else if (data) {
					window.open(data?.search_attributes?.data[0]?.path, "_blank");
					loading.value = false;
				}

				loading.value = false;
				sub.unsubscribe();
			});
		};

    onUnmounted(() => {
      observable.value?.unsubscribe();
      searchObservable.value?.unsubscribe();
    });
		return {
			attributes,
			pagination,
			system_locale,
			currentPage,
			permissions,
			columns,
			loading,
			loader,
			exportFile,
			get_attributes,
			searchHandler,
			handleEdit,
			handleDelete,
			handleCurrentChange,
			activeIndex,
			dropdownHandler,
			handleClickOutside,
		};
	},
});
