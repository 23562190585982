import { gql } from "graphql-tag";
import { ATTRIBUTE_FIELDS } from "./Fragments";

export const CREATE_ATTRIBUTE = gql`
	mutation CreateAttribute($input: AttributeInput!) {
		create_attribute(attribute: $input) {
			...AttributeFields
		}
	}
	${ATTRIBUTE_FIELDS}
`;

export const UPDATE_ATTRIBUTE = gql`
	mutation UpdateAttribute($input: AttributeInput!) {
		update_attribute(attribute: $input) {
			...AttributeFields
		}
	}
	${ATTRIBUTE_FIELDS}
`;

export const DELETE_ATTRIBUTE = gql`
	mutation DeleteAttribute($id: Int!) {
		delete_attribute(id: $id) {
			...AttributeFields
		}
	}
	${ATTRIBUTE_FIELDS}
`;

export default { CREATE_ATTRIBUTE, UPDATE_ATTRIBUTE, DELETE_ATTRIBUTE };
